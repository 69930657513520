import React, { useEffect, useState } from "react";
import './mainPage.css'
import { getSongHistory } from "./utils/nerveApi";
import { getCurrentShow } from "./utils/scheduleHelper";

export default function MainPage({ hide, fade, schedule, shows }) {

    const [currentShow, setCurrentShow] = useState(null);
    const [currentSong, setCurrentSong] = useState(null);
    const [previousSong, setPreviousSong] = useState(null);

    const retrieveHistory = async () => {
        const songHistory = await getSongHistory().catch(console.warn);
        let validTracks = songHistory?.filter(s => s.track !== undefined);
        if (currentSong !== validTracks[0]) setCurrentSong(validTracks[0]);
        if (previousSong !== validTracks[1]) setPreviousSong(validTracks[1]);
    }

    useEffect(() => {
        retrieveHistory();
        (async () => {
            setInterval(retrieveHistory, 10000)
        })()
    }, [])

    useEffect(() => {
        setCurrentShow(getCurrentShow(schedule, shows))
    }, [schedule, shows])

    // <a target={"_blank"} href="https://facebook.com/nerveradiobu/"><i className="icon-facebook-rect"></i></a>

    return (
        <>
            <header className={(hide ? "hide" : "") + (fade ? " fade" : "")}>
                <img src="/assets/logo.png" alt="Brand Logo" />
            </header>
            <section id="mainPage" className={fade ? "hide" : ""}>
                <div className="mainPageContent">
                    <div className="currentShows-card">
                        <div className="currentShows-card-shadow" />
                        <div className="currentShows-card-socialstitle">
                            <span>Find us online</span>
                        </div>
                        <div className="currentShows-card-socialsbuttons">
                            <span>
                                
                                <a target={"_blank"} href="https://instagram.com/nerveradiobu"><i className="icon-instagram"></i></a>
                                <a target={"_blank"} href="https://twitter.com/nerveradio"><i className="icon-twitter"></i></a>
                            </span>
                        </div>
                        <div className="currentShows-card-onairtime">
                            <span>{currentShow?.slot?.timeStart || "00:00"}-{currentShow?.slot?.timeEnd || "00:00"}</span>
                        </div>
                        <div className="currentShows-card-onairshow">
                            <span>ON AIR » {currentShow?.Name || ""}</span>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '30px', padding: '0 15px 100px 15px' }}>
                    <div>
                        <p>{currentShow?.Description || 'Listen to Nerve Radio online throughout the year! We host a variety of shows all run by students'}</p>
                        {currentShow?.Instagram &&
                            <p className="InstagramInfo">
                                <i className="icon-instagram"></i>
                                <a href={"https://instagram.com/"+currentShow?.Instagram} target="_blank">{currentShow?.Instagram}</a>
                            </p>
                        }
                    </div>
                    <div style={{
                        background: '#a30041',
                        color: 'white',
                        padding: '10px 0',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        maxWidth: '400px',
                        margin: 'auto',
                        marginBottom: '5px'
                    }}>
                        {currentSong?.track?.spotify?.album_art === undefined && <i className="icon-music" style={{ marginLeft: '10px', fontSize: '30px', color: 'lightgray', marginRight: '10px', zIndex: 3 }}></i>}
                        {currentSong?.track?.spotify?.album_art !== undefined && <img src={currentSong.track.spotify.album_art} style={{ marginLeft: '10px', height: '50px', borderRadius: '10px', zIndex: 3, marginRight: '10px' }} />}
                        <div style={{ zIndex: 3 }}>
                            <span>{currentSong !== null && currentSong.track.title}</span><br />
                            <img src="/assets/audioplaying.gif" style={{ marginRight: '10px' }} />
                            <span style={{ color: 'lightgray' }}>{currentSong !== null && currentSong.track.artist}</span>
                        </div>
                        {currentSong?.track?.spotify?.external_url !== undefined &&
                            <a href={currentSong?.track?.spotify?.external_url} target={'_blank'} style={{ position: 'absolute', bottom: '6px', right: '6px', fontSize: '20px', color: 'lightgray' }}>
                                <i className="icon-spotify"></i>
                            </a>
                        }
                        <div style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden', opacity: 0.3, pointerEvents: 'none' }}>
                            <img src={currentSong?.track?.spotify?.album_art} style={{ width: '100%', top: '50%', transform: 'translateY(-50%)' }} />
                        </div>
                    </div>
                    <div style={{
                        background: '#a30041',
                        color: 'white',
                        padding: '10px 0',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        maxWidth: '400px',
                        margin: 'auto',
                        marginBottom: '5px'
                    }}>
                        {previousSong?.track?.spotify?.album_art === undefined && <i className="icon-music" style={{ marginLeft: '10px', fontSize: '30px', color: 'lightgray', marginRight: '10px', zIndex: 3 }}></i>}
                        {previousSong?.track?.spotify?.album_art !== undefined && <img src={previousSong.track.spotify.album_art} style={{ marginLeft: '10px', height: '50px', borderRadius: '10px', zIndex: 3, marginRight: '10px' }} />}
                        <div style={{ zIndex: 3 }}>
                            <span>{previousSong !== null && previousSong.track.title}</span><br />
                            <span style={{ color: 'lightgray' }}>{previousSong !== null && previousSong.track.artist}</span>
                        </div>
                        {previousSong?.track?.spotify?.external_url !== undefined &&
                            <a href={previousSong?.track?.spotify?.external_url} target={'_blank'} style={{ position: 'absolute', bottom: '6px', right: '6px', fontSize: '20px', color: 'lightgray' }}>
                                <i className="icon-spotify"></i>
                            </a>
                        }
                        <div style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden', opacity: 0.3, pointerEvents: 'none' }}>
                            <img src={previousSong?.track?.spotify?.album_art} style={{ width: '100%', top: '50%', transform: 'translateY(-50%)' }} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}