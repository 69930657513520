import React from "react";
import Wave from "react-wavify";
import { isMobile } from 'react-device-detect';

export default function WavyBottom({ fullscreen = false, children, expanded, modalOpen }) {
    return (
        <div className={"player " + (!fullscreen ? "show" : "hide") + (expanded ? " expand" : "") + (isMobile ? " mobile" : "") + (modalOpen ? " modal-open" : "")} style={{ width: "100%" }}>
            {children}
            <div id="wave">
                <Wave style={{ zIndex: 10 }} options={{ speed: 0.25, amplitude: 10, points: 3, height: 10 }} fill="#e00464" />
            </div>
        </div>
    )
}